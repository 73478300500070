import { ProductCardCarousel } from '../../../shared';
import { useValidProducts } from '@ui/store/recentlyViewedStore';
import cn from '@ui/utils/cn';
import { SwiperOptions } from 'swiper/types';

type HomeRecentlyViewedCarouselProps = {
  className?: string;
  alignTitleToCarousel?: boolean;
  carouselRootClassName?: string;
  swiperOptions?: SwiperOptions;
};

const HomeRecentlyViewedSection = ({
  className,
  alignTitleToCarousel,
  carouselRootClassName,
  swiperOptions = {
    spaceBetween: 16,
    slidesPerView: 2.2,
    breakpoints: {
      640: { slidesPerView: 2.2 },
      768: { slidesPerView: 3.2 },
      1024: { slidesPerView: 4.2 },
      1200: { slidesPerView: 5.2 },
  }}
}: HomeRecentlyViewedCarouselProps) => {
  const products = useValidProducts();

  if (!products?.length) {
    return null;
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between mb-3 md:mb-6">
        <div className={cn('text-sm md:text-base font-bold tracking-[0.66px]', alignTitleToCarousel && 'pl-2')}>
          RECENTLY VIEWED
        </div>
      </div>
      <div className={cn('md:px-2',carouselRootClassName)}>
        <ProductCardCarousel
          layout="product"
          carousel={{ tag: '', placement: 'home-page', products }}
          swiperOptions={swiperOptions}
          height={450}
          width={300}
          withControls
        />
      </div>
    </div>
  );
};

export default HomeRecentlyViewedSection;
