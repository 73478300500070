import React, { useState } from 'react';
import Image from '@ui/components/core/image';
import Link from 'next/link';

type LinkFeatureCardProps = {
  src: string;
  alt: string;
  label: string;
  href: string;
  height: number;
  width: number;
  onClick?: () => void;
  onDoubleClick?: () => void;
};

const LinkFeatureCard = ({
  src,
  alt,
  label,
  href,
  height,
  width,
  onClick,
  onDoubleClick,
}: LinkFeatureCardProps): React.ReactElement => {
  const [active, setActive] = useState(false);

  return (
    <Link href={href}>
      <Image
        className='md:hidden'
        onClick={
          active
            ? () => {
                onClick?.();
                setActive(true);
              }
            : () => {
                onDoubleClick?.();
                setActive(false);
              }
        }
        src={src}
        alt={alt}
        height={height}
        width={width}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
        srcSet={`${src} 300w, ${src} 480w, ${src} 477w`}
      />
      <Image
        className='hidden md:block'
        onClick={
          active
            ? () => {
                onClick?.();
                setActive(true);
              }
            : () => {
                onDoubleClick?.();
                setActive(false);
              }
        }
        src={src}
        alt={alt}
        height={height}
        width={width}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      {label && (
        <div className='flex justify-center items-center h-[3.375rem]'>
          <div className="border-b-[0.0625rem] border-black">
            <p className="font-bold uppercase text-xs xs:text-base">
              {label}
            </p>
          </div>
        </div>
      )}
    </Link>
  );
};

export default LinkFeatureCard;
