import React from 'react';
import { LinkFeatureCard } from '../../../shared';
import { useStaticContent } from '@ui/providers/static-content-provider';

const HomeCategorySection = (): React.ReactElement => {
  const images = useStaticContent('Home.FeaturedCategories');

  return (
    <div className='overflow-hidden'>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-6">
        {images.map(({ alt, height, href, label, src, width }) => (
          <div className="hover:cursor-pointer" key={alt}>
            <LinkFeatureCard src={src} alt={alt} label={label ?? ''} href={href ?? ''} height={height} width={width} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeCategorySection;
