import React from 'react';
import { Container } from '@ui/components/core/container';
import { type Video } from '@ui/providers/static-content-provider';
import { type HomeHeroSectionProps } from './HomeHeroSection';
import Link from 'next/link';
import cn from '@ui/utils/cn';

type HomeHeroWithVideoSectionProps = HomeHeroSectionProps & {
  desktopVideo: Video;
  mobileVideo: Video;
};

/*
  Hero banner for full size video,
*/
const HomeHeroVideoBanner: React.FC<HomeHeroWithVideoSectionProps> = ({
  url,
  desktopVideo,
  mobileVideo,
}) => {

  return (
    <Link href={url} className="relative block w-full">
      <div className={cn(!mobileVideo.src ? "flex items-end w-full h-full":"hidden","sm:block sm:w-unset sm:h-unset sm:inset-0 pointer-events-none")}>
        <Container className="w-full px-0">
          <video
            autoPlay
            className="w-full h-auto"
            loop
            muted
            playsInline
            poster={desktopVideo.poster.src}
            preload="metadata"
          >
            <source src={desktopVideo.src} type="video/mp4" />
          </video>
        </Container>
      </div>
      {mobileVideo.src && <div className="sm:hidden pointer-events-none flex items-end w-full h-full">
        <video
          autoPlay
          className="w-full h-auto"
          loop
          muted
          playsInline
          poster={mobileVideo.poster.src || desktopVideo.poster.src}
          preload="metadata"
        >
          <source src={mobileVideo.src || desktopVideo.src} type="video/mp4" />
        </video>
      </div>}
    </Link>
  );
};

export default HomeHeroVideoBanner;
